﻿.accordion {
    margin-bottom: 0px;

    .xloc-content-container-module.sql-container-module{
        padding: 0px;
    }

    a.accordion-title {
        font-weight: 700;
        font-size: 14px;
        padding: 10px 15px;
    }
}
