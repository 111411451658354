body, h1, h2, h3, h4, h5, h6, p, li {
  font-size: 16px;
  color: $black;
  line-height: 1.2em;
}

h1 {
  font-size: 36px;
  color: white;
}

h2 {
  font-size: 30px;
  color: white;
}

h3 {
  font-size: 26px;
  color: white;
}

h4 {
  font-size: 20px;
  color: white;
}

h5 {
  font-size: 18px;
}

h6 {
  color: $black;
  font-size: 14px;
}

p {
  line-height: 1.4em;
}

li {
  line-height: 1.4em;
}

