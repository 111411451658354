.answer {
    border-bottom: 1px solid $dark-gray;
    padding: 0;
    margin: 4px 0 18px;

    input[type=text], input[type=password] {
        border: none;
        box-shadow: none;
        margin: 0;
        width: 100%;
    }

    p {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    span {
        /*width: 100% !important;*/
        [type=text] {
            display: block;
            box-sizing: border-box;
            width: 100%;
            height: 2.4375rem;
            padding: 0.5rem;
            border-style: none !important;
            margin: 0 0 1rem;
            font-family: inherit;
            font-size: 1rem;
            color: #0a0a0a;
            background-color: #fefefe;
            -webkit-appearance: none;
            box-shadow: none;
            margin-left: 20px;
            font-style: italic;
            font-weight: 400;
        }
    }
}

input[name=code_page] {
    height: 20px;
}

.build-send-language-label {
    margin-top: 16px;
    margin-right: 10px;
}

textarea {
    font-weight: 100;
}

.RadDropDownList_Default {
    width: 100% !important;
    border: none;
    border-bottom: 1px solid #818088;

    .rddlInner {
        padding: 0.5rem !important;
        height: 2.4375rem;
        border: none !important;
        background-image: none !important;
        border-bottom: 1px solid #818088;
        color: #818088;
        font-weight: 400;
        font-style: italic;
        font-size: 12px;
        border-radius: 0px !important;
        background-color: transparent !important;

        &.rddlDisabled {
            /*background-color: #e6e6e6;*/
            cursor: default;
        }

        .rddlFakeInput {
            padding-top: 4px;
            color: #818088;
            font-weight: 400;
            font-style: italic;
            font-size: 12px;
        }
    }

    .rddlIcon {
        margin-top: 12px;
        margin-right: 5px;
    }
}

.rddlSlide {
    .rddlList {
        .rddlItem {
            color: #818088;
            font-weight: 400;
            font-style: italic;
            font-size: 12px;
        }
    }
}

.text-align-swich{
    text-align: right;
}
