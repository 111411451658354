.xloc-header {
    background: $med-blue;
    color: white;
    border-bottom: 1px solid $border;

    a {
        color: white;
    }

    .xloc-page-title {
        padding-left: 30px;
        min-height: 55px;

        @include breakpoint(small down) {
            padding-left: 15px;
        }
    }
}

.xloc-subheader {
    background: $med-blue;
    padding: 0 30px;

    li {
        font-size: 15px;
    }

    a {
        color: white;
    }

    .xloc-subheader-menu {
        list-style-type: none;
        margin: 0;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        //padding-bottom: 10px;
        min-height: 45px;

        li {
            flex: 1 1 auto;
            text-align: center;
            display: flex;
            align-items: center;

            @include breakpoint(small only) {
                padding: 10px 10px 0 9px;
            }

            &.active {

                a {
                    font-weight: 600;
                    position: relative;

                    &:before {
                        content: "";
                        top: calc(100% + 4px);
                        height: 6px;
                        left: 0;
                        right: 0;
                        background: white;
                        position: absolute;
                    }
                }
            }
        }
        //@include breakpoint(xlarge up){
        li {
            flex: 0 1 auto;
            text-align: left;
            max-width: none;
            margin-right: 1.5vw;
        }
        //}
        @include breakpoint(medium donw) {
        }
    }
}

.xloc-page-title {
    h1 {
        margin: 10px 0;
        font-size: 22px;
        line-height: 35px;

        @include breakpoint(small only) {
            font-size: 17px;
            line-height: 28px;
            margin-bottom: 0;
        }

        i {
            margin-right: 15px;
            font-size: 10px;
            border: 2px solid white;
            width: 35px;
            height: 35px;
            display: block;
            border-radius: 100%;
            float: left;

            @include breakpoint(small only) {
                width: 30px;
                height: 30px;
                margin-right: 10px;
            }

            &:before {
                width: 100%;
                margin: 0;
                position: relative;
                font-size: 10px;
            }
        }
    }

    @include breakpoint(small down) {
        height: 60px;
        display: flex;
        align-items: center;

        h1 {
            margin: 0;
        }
    }
}

.xloc-user-info {
    border-left: 1px solid $border;
    justify-content: flex-end;
    display: flex;
    align-items: center;

    h2 {
        font-size: 16px;
        margin: 0;

        @include breakpoint(small only) {
            font-size: 16px;
        }
    }

    i {
        margin-right: 5px;
    }
}

.xloc-page-content .xloc-language-subheader {
    background: white;
    display: flex;
    list-style-type: none;
    margin: 0;
    flex-wrap: wrap;
    padding: 0 30px;

    li {
        flex: 0 1 auto;
        margin-right: 3vw;
        text-align: center;

        @include breakpoint(small only) {
            padding: 10px 10px 5px 10px;
        }

        &.active {
            a {
                position: relative;
                font-weight: 600;

                &:before {
                    content: "";
                    position: absolute;
                    top: calc(100% + 5px);
                    height: 6px;
                    left: 0;
                    right: 0;
                    background: $navy;
                }
            }
        }
    }

    a {
        color: $black;
        line-height: 70px;

        @include breakpoint(small only) {
            line-height: 1.1em;
        }
    }

    @include breakpoint(small down) {
        display: none !important;
    }
}
