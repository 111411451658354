ul.xloc-sidebar-menu {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;

    li {
        position: relative;
        padding-right: 7px;

        i {
            float: right;
            font-size: 12px;
            color: white;
            transform: rotate(180deg);
            opacity: .9;
        }

        ul {
            list-style-type: none;
            background: $menu-dd;
            padding: 15px 0;
            margin-top: 15px;

            li {
                padding: 0 15px 0 45px;
            }
        }

        &.open {
            &:before {
                content: "";
                position: absolute;
                top: -4px;
                left: 0;
                width: 4px;
                background: white;
                height: 33px;
            }

            a {
                font-weight: 600;
            }

            ul a {
                font-weight: 400;
            }

            i {
                transform: rotate(0deg);
                opacity: 1;
            }
        }
    }

    a {
        font-size: 14px;
        color: white;
        padding: 0 10px;
    }

    @include breakpoint(1200px down) {
        // this block will be used to control the dropdown behavior of the sidebar menu (now displayed at the top)
        padding: 15px;

        .open {
            position: relative;

            &:before {
                display: none;
            }

            ul {
                display: block;
                position: absolute;
                left: 0;
                top: calc(100% + 2px);
                margin: 0;
                z-index: 999;
                padding: 15px;
                width: 150px;
                background: $med-blue;

                li {
                    padding: 7px 0;

                    &:first-of-type {
                        padding-top: 0;
                    }

                    &:last-of-type {
                        padding-bottom: 0;
                    }
                }

                a {
                    display: block;
                    padding: 0;
                }
            }
        }

        ul {
            display: none;
        }
    }

    @include breakpoint(1200px up) {
        padding: 0;
        padding-top: 15px;
        flex-wrap: none;
        flex-direction: column;

        li {
            padding: 0 15px 0 30px;
            margin: 10px 0;
        }

        ul {
            margin: 0 -15px 0 -30px;
        }
    }
}

.xloc-mobile-menu-trigger {
    background: $med-blue;
    color: white;
    border-left: 1px solid $sidebar-dd;
    transition: 400ms all ease-out;
    min-height: 55px;

    @include breakpoint(1200px up) {
        display: none;
    }

    p {
        font-size: 14px;
        line-height: 50px;
        margin: 0;
        color: white;
    }

    .icon {
        padding: 17px 15px;
        width: 49px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    i {
        font-size: 18px;
    }

    &.open {
        background: $dark-navy;

        .column.icon {
            border-left-color: $dark-navy;
        }
    }
}

.LeftPane.xloc-sidebar {

    > div.column {
        background-color: #F1F1F1;
        padding-bottom: 25px;
        padding-top: 15px;
        border: none;
    }
}

.icon-dd-indicator:hover {
    cursor: pointer;
}

.xloc-mobile-menus {
    display: none;
    flex-direction: column;

    @include breakpoint(1200px up) {
        display: none !important;
    }

    & > .column {
        background: $med-blue;
        transition: 400ms all ease-out;
        width: 100%;
        position: relative;
        padding: 0 0 0 15px;
        cursor: pointer;


        &.open {
            background: $dark-navy;

            i {
                border-left: $light-gray;
            }
        }

        & > p {
            font-size: 13px;
            margin: 0;
            color: $lighter-gray;
            float: left;
            line-height: 35px;
        }

        i {
            font-size: 10px;
            color: $lighter-gray;
            float: right;
            border-left: 1px solid $sidebar-dd;
            line-height: 35px;
            width: 50px;
            text-align: center;

            &:before {
                position: relative;
                left: -2px;
            }
        }

        & > ul {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            list-style-type: none;
            background: $dark-navy;
            margin: 0;
            padding: 15px 45px;
            flex-direction: column;
            display: none;
            z-index: 10;

            ul {
                position: relative;
                background: transparent;
                padding-left: 15px;
                width: 100%;
                z-index: inherit;
            }

            li {
                margin: 0;
                padding: 0 !important;
            }

            a {
                color: white;
                font-size: 15px;
                margin: 0;
                padding: 0;
                display: block;
                line-height: 1.8;
            }

            i {
                display: none;
            }
        }
    }
}

