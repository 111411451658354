﻿body.sign-in {
    background-image: url(/images/main-background.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #464646;

    .xloc-page-content {
        background: transparent;
    }

    .xloc-mobile-menu-trigger, .xloc-subheader, .xloc-user-info {
        display: none !important;
    }
}

table tr td div {
    overflow: hidden;
    text-overflow: ellipsis;
}

.xloc-page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    & > .row {
        width: 100%;
    }

    .xloc-header, .xloc-subheader {
        flex: 0 0 auto;
    }

    .xloc-subheader {

        @include breakpoint(1200px down) {
            display: none !important;
        }
    }

    .xloc-content-container {
        flex: 1 1 auto;
    }
}

.xloc-content-container {
    flex-wrap: nowrap;
    flex-direction: column;

    & > .xloc-sidebar {
        flex: 0 0 auto;

        @include breakpoint(1200px down) {
            display: none;
        }
    }

    & > .xloc-page-content {
        flex: 1 1 auto;

        .row {
            margin: 0 !important;
        }
    }

    @include breakpoint(1200px up) {
        flex-direction: row;

        & > .xloc-sidebar {
            flex: 0 0 250px;
        }
    }
}

.find-replace-container {
    .column.extended {
        margin-bottom: 15px;
    }
}

.language-change-container {
    label {
        font-weight: 700;
        padding-top: 10px;
    }

    .answer {
        margin-top: 0px;
    }
}

a.button {
    background: $med-blue;
    margin: 10px;
    padding: 15px 65px;
    font-weight: 700;

    @include breakpoint(small only) {
        margin-bottom: 5px;
    }

    &.secondary {
        background: $navy;
    }

    &.alert {
        background: $orangish;
    }

    &.warning {
        background: $dark-gray;
    }
}

.button-sm, a.button-sm {
    padding: 9px 20px;

    &.table-extend-btn{
        margin: 0;
        padding: 5px 20px;
    }
}

.button-round, a.button-round {
    padding: 3px 10px;
    margin: 0;
    font-size: 12px;
    border-radius: 10px;
}

.round-button-container {

    &.secondary {

        a {
            background: $navy;
        }
    }

    &.alert {
        a {
            background: $orangish;
        }
    }

    &.warning {
        a {
            background: $dark-gray;
        }
    }

    a {
        color: white;
        font-weight: 700;
        background: $med-blue;
        padding: 3px 10px;
        margin: 0;
        font-size: 12px;
        border-radius: 10px;
    }
}

.instructions-container {
    position: absolute;
    padding: 20px;
}

.radio-buttons-container {
    width: auto;

    tbody {
        border: none;
    }

    td {
        width: 30%;

        label {
            font-weight: 700;
        }

        input[type='radio'] {
            margin: 0px;
        }
    }
}

.radio-buttons-list {
    tbody {
        tr {
            background-color: white !important;

            td {
                padding: 0;

                input[type='radio'] {
                    margin: 0px;
                }
            }
        }
    }
}

.row {
    max-width: none;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-footer-info {
    padding-bottom: 15px;
    padding-left: 15px;
}

.xloc-string-update-info {
    font-style: italic;
    font-size: 13px;
    margin-bottom: 15px !important;
}

.sql-code {
    display: flex;
    overflow: auto;
    background-color: #FFFFFF;
    word-break: normal !important;
    word-wrap: normal !important;
    white-space: pre-wrap !important;

    code {
        overflow-wrap: normal;
        white-space: pre;
    }
}

.xloc-string-update-info-table {
    padding-bottom: 15px;
    padding-left: 15px;
}

.additional-flags table {

    tbody {
        border: none;

        tr:nth-child(even) {
            background-color: transparent !important;
        }

        .NormalBold {
            width: 30%;
            padding-top: 20px;
        }

        .Normal {
            border-bottom: 1px solid #818088;
            padding: 0;
            margin: 4px 0 18px;

            input {
                border: none;
                box-shadow: none;
                margin: 0;
                width: 100%;
            }
        }
    }
}

.string-count {
    float: right;
}

.modified-date-select {
    margin-top: 7px;
}

.without-margin {
    &.left {
        margin-left: 0 !important;
    }

    &.right {
        margin-right: 0 !important;
    }

    &.top {
        margin-top: 0 !important;
    }

    &.bottom {
        margin-bottom: 0 !important;
    }
}

.login-container-wrap {
    background-image: url();
    background: url() no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .login-container {
        margin-top: 90px;

        .forgot-password {
            font-size: 0.875rem;
            float: right;
            margin-top: 4px;
            margin-bottom: 11px;
        }
    }
}

.dropdownt-inline-with-input {
    margin-top: 5px;
}

.timed-radio-button-element {
    margin-top: 20px;
}

/*TREE_VIEW*/

.tree-view {

    table {
        width: initial;

        td {
            padding: 5px;

            img {
                margin-bottom: 15px;
            }
        }
    }
}

.ruFakeInput {
    font-size: 12px !important;
    margin-left: 1px !important;
    margin-top: -6px !important;
}

.tabs-controls-table {
    tr {
        background-color: white !important;
    }
}

tr.numeric-pager {
    height: 35px;
    display: table-row;

    td {
        font-weight: 100;
        font-size: $pagination-font-size;
        color: $pagination-item-color;
        display: table-cell;
        padding: 15px;

        a {
            color: $pagination-item-color;
            display: inline-flex;
            margin: 0 3px;
            height: 26px;
            width: 26px;
            justify-content: center;
            flex-direction: column;
            text-align: center;
        }

        span {
            color: white;
            background: $pagination-item-background-current;
            border-radius: 50%;
            height: 26px;
            width: 26px;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            display: inline-flex;
        }
    }
}

.RadCaptcha.RadCaptcha_Default {
    p {
        label {
            display: none;
        }
    }
}

.xloc-subheader .xloc-subheader-menu li {
    height: 45px;
}

.xloc-language-subheader {
    background: #2960ad;
    display: -ms-flexbox;
    display: flex;
    list-style-type: none;
    margin: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 30px;

    li {
        flex: 0 1 auto;
        margin-right: 1.5vw;
        text-align: center;
        font-size: 15px;

        @include breakpoint(small only) {
            padding: 10px 10px 5px 10px;
        }

        &.active {
            a {
                position: relative;
                font-weight: 600;

                &:before {
                    content: "";
                    position: absolute;
                    top: calc(100% + 1px);
                    height: 6px;
                    left: 0;
                    right: 0;
                    background: $navy;
                }
            }
        }
    }

    a {
        color: #eeeeee;
        line-height: 29px;

        @include breakpoint(small only) {
            line-height: 1.1em;
        }
    }

    @include breakpoint(1200px down) {
        display: none;
    }
}

table.radio-buttons-list tbody {
    border: none;
}

.percentcomplete {
    border: 1px solid #aaaaaa;
    margin-top: 1px;
}

.info-container table tbody {
    border: none;
}

/*.language-menu.open {
    .xloc-language-subheader-mobile {
        @include breakpoint(F) {
            display: block !important;
        }
    }
}*/
