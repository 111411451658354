.xloc-string-buttons {
    padding: 30px 15px;

    .button {
        margin: 0 5px;
        padding: 15px 0;

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}

.xloc-content-container-module {
    margin: 0 15px 15px 15px;
    background-color: #FFF;

    &.text {
        padding: 15px;
    }

    &.sql-container-module {
        padding: 15px;
    }
}
