.xloc-form-submit-buttons {
    .button {
        background: $navy;
        font-size: 14px;
        width: 100%;
        height: 100%;
        margin-left: 0;
    }
}

.basic {
    .row.align-center.align-middle {
        background: $light-gray;
        margin-left: 0;
        padding: 15px 0;

        .button {
            background: $med-blue;
            padding: 13px 38px;
            margin: 0 10px;
            font-weight: 600;

            @include breakpoint(small down) {
                width: 100%;
                margin-bottom: 5px;
            }
        }
    }

    h6.header {
        margin-left: 15px;
        font-weight: 700;
        margin-top: 20px;
    }
}

.search-box {
    border: 1px solid rgba(128, 128, 128, 0.29);
    border-radius: 6px;
    padding: 0px 10px 5px 10px;
    margin-bottom: 5px;
    margin-left: 4px;
}

.xloc-string-search {
    padding: 0 15px;

    .row {
        margin: 0;
    }
}

.xloc-string-search-section {
    border-right: 1px solid $light-gray;
    padding: 0;

    h6 {
        margin-left: 15px;
        font-weight: 700;
        margin-top: 20px;
    }

    input {
        border: none;
        border-bottom: 1px solid $dark-gray;

        @include breakpoint(small only) {
            margin-left: 5px;
        }
    }

    .fontello-input {
        margin-right: 20px;
        color: $dark-gray;
        background: white;
        border-bottom: none;
        padding: 0;
        font-size: 22px;
    }

    @include breakpoint(medium up) {
        &:last-of-type {
            padding-right: 0px;
        }
    }

    @include breakpoint(medium down) {
        flex: 1 1 33%;

        &.xloc-form-submit-buttons {
            flex: 0 0 auto;
        }
    }

    @include breakpoint(small down) {
        flex: 1 1 100%;
    }

    &.checkbox-list {

        label {
            font-size: 12px;

            &.header {
                font-size: 14px;
            }
        }
    }

    .search-block {
        @include breakpoint(small down) {
            width: 100%;
            padding: 0 15px;

            input {
                margin: 0;
            }
        }
    }
}

input.box-info {
    margin-bottom: 0;
}

input#search_term {
    margin-left: 0;
}

.adding-border {
    @include breakpoint(medium down) {
        border-top: 1px solid $light-gray;
    }
}

input.key-search {
    width: 101%;
}

label.title-resource {
    font-weight: 700;
    margin-left: 3px;

    @include breakpoint(small only) {
        margin-left: 0;
    }
}

.title-source {
    font-weight: 700;
}

[type='text'] {
    box-shadow: none;
    width: 80%;
    margin-left: 20px;
    font-weight: 400;
    color: #FFFFFF;
    font-size: 14px;
    padding: 0;
}

*::-webkit-input-placeholder {
    font-style: italic;
    color: #dcdcdc;
}

*:-moz-placeholder {
    font-style: italic;
    color: #dcdcdc;
}

*::-moz-placeholder {
    font-style: italic;
    color: #dcdcdc;
}

*:-ms-input-placeholder {
    font-style: italic;
    color: #dcdcdc;
}

[type='text'] {
    box-shadow: none;
    width: 80%;
    margin-left: 20px;
    font-weight: 400;
    color: #0a0a0a !important;
    font-size: 14px;
    padding: 0;
}

textarea, select{
    color: #0a0a0a !important;
    font-size: 14px !important;

}

input::placeholder,
textarea::placeholder {
    color: #cacaca;    
    font-size: 12px;
}

select#order_by {
    border: none;
    border-bottom: 1px solid $dark-gray;
    font-size: 12px;
    font-style: italic;
    width: 90%;
    margin-left: 15px;

    @include breakpoint(small only) {
        margin-left: 15px;
        width: calc(100% - 30px);
    }
}

select#source_file {
    border: none;
    border-bottom: 1px solid $dark-gray;
    font-size: 12px;
    font-style: italic;
    width: 90%;
    margin-left: 15px;

    @include breakpoint(small only) {
        margin-left: 15px;
        width: calc(100% - 30px);
    }
}

.radio {
    align-items: center;
    height: 44px;

    .columns {
        text-align: center;
    }

    @include breakpoint(small down) {
        .columns {
            flex: 1 1 auto !important;
            padding-top: 12px;
        }
    }
}

.advanced {
    background: white;
    margin-top: 15px;
    margin-bottom: 0px;

    .row {
        margin: 0 0 20px 0;

        .xloc-string-search-section {
            border-right: none;

            label {
                margin-left: 18px;
                margin-top: 5px;

                &.bold {
                    font-weight: 700;
                }

                @include breakpoint(small only) {
                    margin-left: 5px;
                }

                .advanced_additional_search_fields {
                    font-weight: 400;
                }
            }

            @include breakpoint(small down) {
                padding: 0 15px;

                input[type=text] {
                    width: 100%;
                }

                select {
                    margin: 0 !important;
                    padding: 0 !important;
                    width: 100% !important;
                }

                .radio {
                    height: inherit;
                }
            }
        }
    }

    @include breakpoint(small down) {

        .button {
            margin: 25px 0 0 0;
        }
    }
}

.search-check-list-table {
    margin-left: 15px;
    width: auto;

    tbody {
        border: none;

        tr {
            border: none;
            background-color: $white;

            td {
                background-color: $white;
                border: none;
                padding: 1px 0px 0px 0px;

                label {
                    margin: 0px !important;
                }

                input {
                    margin: 5px !important;
                }
            }
        }
    }
}

select#advanced_contains {
    border: none;
    border-bottom: 1px solid $dark-gray;
    margin-left: 18px;
    width: 78%;
    color: $dark-gray;
    font-size: 12px;
}

select {
    color: $dark-gray;
    font-weight: 400;
    border-bottom: 1px solid $dark-gray;
    font-size: 12px;
}

.xloc-string-meta {
    select {
        padding-bottom: 0;
        padding-top: 0;
        /*margin-bottom: 3px;*/
    }
}

label.header {
    font-weight: 700;
}

label > [type='checkbox'] {
    margin-right: 1px;
}

.small-checkbox {

    label {
        margin-left: 0px !important;
        margin-right: 0px !important;
        font-size: 12px !important;
    }

    input {
        margin: 5px;
    }
}

.icon-lock.disabled {
    color: gray;
}

.icon-lock {
    color: #2199e8;
    font-size: 20px;
}
