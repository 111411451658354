$med-blue: #3b6db4;
$navy: #253b75;
$orangish: #f46a39;
$lighter-gray: #efefef;
$light-gray: #e2e2e2;
$dark-gray: #818088;
$dark-navy: #272d39;
$black: #292929;
$border: #3760a0;
$sidebar-dd: rgb(41, 72, 139);
$menu-dd: rgb(24, 28, 37);

.one-px-line-light {
  width: 100%;
  border-bottom: 1px solid $light-gray;
}

.one-px-line-dark {
  width: 100%;
  border-bottom: 1px solid $dark-gray;
}

.one-px-line-darkest {
  width: 100%;
  border-bottom: 1px solid $navy;
}