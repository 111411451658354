.xloc-sidebar {
  background: $sidebar-dd;

  ul.xloc-sidebar-menu {
    margin-left: 0;
    display: flex;
    flex-wrap: wrap;
    @include breakpoint(large down) {
      padding: 15px 30px;
      min-height: 50px;
    }

    li {
      position: relative;
      padding-right: 7px;
      @include breakpoint(large down) {
        padding-right: 20px;
      }

      i {
        float: right;
        font-size: 12px;
        color: white;
        transform: rotate(180deg);
        opacity: .9;
      }

      ul {
        list-style-type: none;
        background: $menu-dd;
        padding: 15px 0;
        margin-top: 15px;
        @include breakpoint(large down) {
          display: none;
        }

        li {
          padding: 0 15px 0 45px;
          @include breakpoint(large down) {
            background: transparent;
          }
        }
      }

      &.open {
        &:before {
          content: "";
          position: absolute;
          top: -4px;
          left: 0;
          width: 4px;
          background: white;
          height: 33px;
          @include breakpoint(large down) {
            display: none;
          }
        }

        a {
          font-weight: 600;
        }

        ul a {
          font-weight: 400;
        }

        i {
          transform: rotate(0deg);
          opacity: 1;
        }
      }

    }

    a {
      padding: 0;
      font-size: 14px;
    }

  }

  @include breakpoint(xlarge up) {
    padding: 0;

    ul.xloc-sidebar-menu {
      flex-direction: column;

      li {
        padding: 0 15px 0 30px;
      }

      ul {
        margin: 0 -15px 0 -30px;
      }

    }

  }
}

.xloc-sidebar-menu {
  list-style: none;
  margin: 0;

  li {
  }

  a {
    color: white;
    padding: 0 10px;

  }

  @include breakpoint(xlarge up) {
    padding-top: 15px;
    margin-left: 30px;

    li {
      margin: 15px 0;

    }
  }

}
