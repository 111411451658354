.xloc-page-content {
    background: $light-gray;
}

.xloc-string-results-header {
    height: 40px;

    .button-sm {
        padding: 6px 16px;
        margin-right: 0px;
    }

    .title {
        h6 {
            color: $black;
            font-size: 20px;
            font-weight: 700;
            margin: 0;

            @include breakpoint(small only) {
                font-size: 17px;
            }
        }
    }

    .pagination {
        margin: 0;
        padding: 0;
    }
}

.xloc-string-results-subheader {
    background: $light-gray;

    .title {
        h2 {
            color: $black;
            font-size: 20px;
            font-weight: 700;
            margin: 20px;
        }
    }
}

ul.xloc-subheader-pagination.menu {
    display: flex;
    margin: 0;

    li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        margin: 0;
        padding: 0;
    }

    a {
        margin: 0;
        padding: 0;

        &.active {
            width: 25px;
            height: 25px;
            text-align: center;
            line-height: 25px;
        }
    }

    a.first,
    a.last,
    a.prev,
    a.next {
        //padding: .7rem .5rem;
    }

    a.active {
        color: white;
        background: $med-blue;
        border-radius: 50%;
    }
}

.basic {
    background: white;
    margin-bottom: 0px;
}

.basic, .advanced {
    padding-bottom: 5px;

    .xloc-string-search-section {
        h6 {
            margin-top: 5px;
            margin-bottom: 0px;
        }

        input[type='text'] {
            margin-bottom: 0px;
        }

        select {
            margin-bottom: 0px;
        }

        .radio {
            height: 21px;
        }
    }
}

.xloc-results {
    padding: 0 15px;
}

.header.row {
    background: white;
    padding: 12px;

    &.row {
        max-width: 100%;
    }

    h4 {
        color: $black;
        font-weight: 600;
    }

    .title {
        padding-left: 80px;

        @include breakpoint(small only) {
            padding-left: 0;
        }
    }

    .source {
        padding-left: 30px;
    }
}

.article.row.align-middle {
    background: $lighter-gray;
    max-width: 100%;

    p {
        margin-top: 15px;
    }
}

.table-highlight-string {
    color: red;
    background: pink;
}

.article.row.align-middle:nth-child(odd) {
    background: white;
    max-width: 100%;
}

.xloc-string-results {
    background: white;
    margin: 0 15px;

    .label {
        background: white;
        padding: 0 10px 11px 0;
    }
}

.xloc-string-meta {
    padding-top: 20px;

    h6 {
        font-weight: 700;
    }

    label {
        font-weight: 700;
    }

    .h6 {
        font-weight: 700;
    }
}

.column.shrink.label {
    h6 {
        font-weight: 700;
    }

    .h6 {
        font-weight: 700;
    }
}

.xloc-string-content {
    h6 {
        font-weight: 700;
    }

    .h6 {
        font-weight: 700;
    }
}

.additional-flags-container {
    h6 {
        font-weight: 700;
    }

    .h6 {
        font-weight: 700;
    }
}

.additional-flags {

    @include breakpoint(large down) {
        padding-left: 0;
    }
}

.priority-source-files-titles {
    label {
        font-weight: 700;
    }

    .h6 {
        font-weight: 700;
    }
}

.xloc-string-results-content {
    width: 100%;
    display: block;

    @include breakpoint(medium up) {
        width: 100%;
    }

    @include breakpoint(large up) {
        width: 100%;
    }

    @include breakpoint(xlarge up) {
        width: 100%;
    }

    @include breakpoint(xxlarge up) {
        width: 60%;
    }

    &.expanded {
        width: 100%;
    }
}

.table, table {
    table-layout: fixed;
}

tr.DGHead td {
    border-left: none;
}

.DGHead, .DGNormal, .DGAlt, .DGEdit, .ColorPriorityLow, .ColorPriorityMedium, .ColorPriorityHigh, .ColorPriorityUrgent, .ColorPriorityBlue, .ColorPriorityBrown, .ColorPriorityGrey, .ColorPriorityLime, .ColorPriorityMagenta, .ColorPriorityOrange, .ColorPriorityPurple, .ColorPriorityWhite, .ColorPriorityNormal, .DGInactive {

    &.expanded {
        td {
            white-space: normal;
            overflow: initial;

            br {
                display: block;
            }
        }
    }

    td {
        padding: 4px 15px;
        border: none;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        /*border-left: 1px solid rgb(197, 197, 197);*/
        &:first-child {
            border-left: none;
        }

        &.table-icon-button {
            overflow: visible;
        }

        &.rgBatchChanged {
            color: #a94442;
        }

        &.editable-cell {
            font-weight: bold;
        }

        br {
            display: none;
        }

        textarea {
            font-style: italic;
            font-weight: 400;
            color: #818088;
            font-size: 12px;
        }
    }
}

.ColorPriorityUrgent {
    background-color: #F2DEDE !important;
}

.ColorPriorityHigh {
    background-color: #FCF8E3 !important;
}

.ColorPriorityMedium {
    background-color: $alert-primary !important;
}

.ColorPriorityLow {
    background-color: #DFF0D8 !important;
}

.ColorPriorityBlue {
    background-color: #0099ff !important;

    a i {
        color: #dedede;
    }
}

.ColorPriorityBrown {
    background-color: #996633 !important;
}

.ColorPriorityGrey {
    background-color: #909090 !important;
}

.ColorPriorityLime {
    background-color: #A0FF10 !important;
}

.ColorPriorityMagenta {
    background-color: #ff3399 !important;
}

.ColorPriorityOrange {
    background-color: #FF8000 !important;
}

.ColorPriorityPurple {
    background-color: #9966ff !important;
}

.ColorPriorityWhite {
    background-color: #FFFFFF !important;
}

.ColorPriorityNormal {
    background-color: #F1F1F1 !important;
}

.rgCommandRow {
    .rgCommandCell {
        padding: 0px;

        .rgCommandTable {



            caption, thead {
                display: none;
            }

            tbody {
                border: none;

                tr {
                    td {
                        &[align=right] {
                            display: none;
                        }

                        &[align=left] {
                            input {
                                display: none;
                            }
                        }

                        a {
                            padding: 5px 20px;
                            background: #3b6db4;
                            font-weight: 700;
                            display: inline-block;
                            text-align: center;
                            line-height: 1;
                            cursor: pointer;
                            -webkit-appearance: none;
                            transition: background-color 0.25s ease-out, color 0.25s ease-out;
                            vertical-align: middle;
                            border: 1px solid transparent;
                            border-radius: 0;
                            margin: 0 1rem 0 0;
                            font-size: 0.9rem;
                            color: #fefefe;
                        }
                    }
                }
            }
        }
    }
}

.DGHead {
    background: white;
    padding: 7px 15px;
    color: $black;
    font-weight: 600;
    font-size: 16px;
    border: none;

    a {
        color: $black;
    }

    td {
        background: white;
        padding: 12px;
        color: $black;
        font-weight: 600;
        font-size: 16px;

        a {
            color: $black;
        }
    }
}

.manage-groups-table-container {
    h6 {
        font-weight: 700;
    }
}

.announcements-table {
    tbody {
        border: none;
    }

    tr.last-row {
        background-color: #E2E2E2;
    }

    tr:not(.must-be-shown) {
        display: none;
    }
}

.DG.validator-grid {
    tr.DGEdit {
        background-color: white !important;
        border-top: 1px solid gray;
        border-bottom: 1px solid gray;
    }
}

.RadGrid.RadGrid_Default.DG {
    padding-bottom: 1px;
    overflow: auto;
    width: initial !important;
}

table tbody {
    border-color: transparent;
}

.bordered-table {
    tbody {
        border-bottom: 1px solid #CACACA;
    }
}

table.flags-table {
    .data-row {
        padding-top: 5px;
    }

    .name-row {
        padding-top: 15px;
        font-weight: 700;
    }
}

.input-error {
    color: red;
    font-size: 12px;
    position: absolute;
    margin-right: 40px;

    &.textbox {
        bottom: -18px;
    }
}

div.input-error {
    color: red;
    font-size: 12px;
    position: relative;
    margin-left: 55px;
    top: -19px;
}

.textbox-with-error {
    position: relative;
}

.errors-container {
    padding: 17px 15px;
    margin: 15px;
    color: #a94442;
    background-color: #f2dede;
    border: 1px solid #ebccd1;
    font-weight: 700;
    font-size: 14px;

    @at-root {
        span#{&} {
            display: block;
        }
    }

    &.expanded, &.extended {
        margin: 15px 0;
    }
}

.info-container {
    padding: 17px 15px;
    margin: 15px;
    color: $alert-primary-font-color;
    background-color: $alert-primary;
    border-color: $alert-primary-border-color;
    font-weight: 700;
    font-size: 14px;

    @at-root {
        span#{&} {
            display: block;
        }
    }

    &.expanded, &.extended {
        margin: 15px 0;
    }

    ul li {
        font-weight: 700;
        font-size: 14px;
        color: $alert-primary-font-color;
    }
}

.table-with-inputs {
    select, input, textarea {
        margin: 0 !important;
    }

    .rgFooter {
        input[type='checkbox'] {
            margin-left: 5px;
        }
    }

    .rgHeader {
        input[type='checkbox'] {
            margin-left: 3px;
        }
    }
}

table {
    margin-bottom: 0;
}

table.table-fix {
    table-layout: auto !important;
}

table tbody tr.DGNormal {
    background-color: #f1f1f1;
}

table tbody tr.DGAlt {
    background-color: #fefefe;
}

table tbody tr.DGInactive {
    background-color: #fbcccc;
    color: #7d7d7d;
}


table:not(.language-list) tbody tr.rgEditRow, table:not(.language-list) tbody tr.DGEdit {
    background-color: #989898;

    + tr {
        background-color: #989898;

        td {
            padding-left: 0px;
            padding-right: 0px;

            .xloc-string-results {
                margin: 0 10px;
            }
        }
    }
}

table.language-list tbody tr.rgEditRow, table.language-list tbody tr.DGEdit {
    background-color: #989898;
}

tr.NormalFoot {
    border-color: transparent;
}

tr.CommandButton {
    td {
        border: none;
    }
}

.questions-table-container {
    padding: 0 15px;
}

.asset-control-container {
    table {
        tbody {
            border: none;

            .include-assert-check {
                padding-top: 15px;
            }
        }
    }
}

tr.header-with-pager {
    background-color: #F8F8F8 !important;

    td {
        padding: 15px 15px;
        border: none;
    }
}

.xloc-two-way-pager {
    span {
        color: $pagination-item-color;
        display: inline-flex;
        margin: 0 3px;
        height: 26px;
        width: 26px;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }

    a {
        color: white;
        background: $pagination-item-background-current;
        border-radius: 50%;
        height: 26px;
        width: 26px;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        display: inline-flex;
    }
}

.xloc-pager {
    display: flex;
    float: right;
    font-size: 0.875rem;
    font-weight: 200;

    &.result-count {
        float: left;
        width: 100px;
        overflow: visible;
    }

    .pager-arrow {
        font-size: 21px;
        font-weight: 700;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        margin: 0 5px;
        padding: 0;

        &.pager-text {
            width: auto;
            font-weight: 700;
            font-size: 0.875rem;
            margin: 0 8px;

            .max-number {
                margin-left: 5px;
            }

            input {
                margin: 0 5px !important;
            }
        }
    }

    input[type='text'], select {
        margin: 0 5px;
        width: 45px;
        height: 25px;
        text-align: center;
    }

    select {
        padding: 0 20px 0 10px;
        width: 55px;
    }
}

.rgPager.numeric-pager {
    table {
        caption {
            display: none;
        }

        thead {
            display: none;
        }

        tbody {
            text-align: right;
            float: right;
            background: transparent;

            tr {
                td.rgPagerCell {
                    padding: 0px;

                    .rgNumPart {

                        a {
                            background: transparent;

                            span {
                                background: transparent;
                                color: #0a0a0a;
                            }

                            &.rgCurrentPage {
                                span {
                                    background: #2199e8;
                                    color: #ffffff;
                                }
                            }
                        }
                    }

                    .rgInfoPart {
                        display: none;
                    }
                }
            }
        }
    }
}
