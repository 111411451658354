@charset 'utf-8';

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
//@include foundation-grid;
@include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;


@import 'components/typography';
@import 'components/variables';
@import 'components/layout';
@import 'components/modules';
@import 'components/search';
@import 'components/header';
@import 'components/sidebar';
@import 'components/results';
@import 'components/menu';
@import 'components/form';
@import 'components/telerik';
@import 'components/accordion';


.home {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin: 0;
            padding: 0;
        }
    }
}

.hidden {
    display: none !important;
}

.column.extended, .columns.extended {
    padding-left: 0px;
    padding-right: 0px;
}

input[type='text'], input[type='password'] {
    margin-left: 15px;
}

input[type='password'] {
    box-shadow: none;
    width: 80%;
    margin-left: 20px;
    font-style: italic;
    font-weight: 400;
    color: #818088;
    font-size: 12px;
    padding: 0;
}

.specific-list-item {
    label {

        input {
            float: right;
            width: 65px;
            height: 27px;
            text-align: center;
        }
    }
}

[type='checkbox'] + label[for], [type='radio'] + label[for] {
    font-weight: 100;
}

.Loader {
    background-image: url("../../../images/ajax-loader.gif");
    background-repeat: no-repeat;
    background-position: center center;
    vertical-align: middle;
}

.required h6:after {
    color: #e32;
    content: ' *';
    display:inline;
}

hr.narrow{
    margin: 2px;
}