﻿.RadInputMgr {
    height: 2.4375rem !important;
}

.RadPicker {

    &.answer.datepicker {
        width: 95% !important;
        margin-left: 11px;
    }

    width: 100% !important;

    .riTextBox {
        border: none !important;
    }

    .rcCalPopup {
        background-image: url();
    }
}

.RadCalendarPopup {
    &.RadCalendarPopupShado{

    }

    .rcShadTR, .rcShadBL, .rcShadBR {
        display: none;
    }
}
